.footer_container {
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  background: none;
  width: 100%;
  padding: 12px 40px;
}
.footer_image {
  position: relative;
  margin-left: auto;
  max-height: 22px;
}
