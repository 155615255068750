.tableTemplate {
  margin-bottom: 50px;
}
.settings_title,
.settings_description {
  text-align: center;
  margin-bottom: 30px;
}
.settings_description {
  margin-bottom: 20px;
  line-height: 20px;
}
.description {
  margin-bottom: 16px;
}
.title {
  margin-bottom: 15px;
}
.error {
  color: #db1544;
  margin: 4px 0;
  max-width: 390px;
}
.col_title,
.first_col_title {
  font-weight: bold;
  color: #333333;
  font-size: 14px;
}
.first_col_title {
  padding-left: 16px;
}