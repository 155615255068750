h1 {
  font-size: 30px;
  font-weight: bold;
}
h2 {
  font-size: 20px;
  font-weight: bold;
}
h5 {
  font-size: 16px;
  margin-top: 10px;
}
h6 {
  font-size: 16px;
  font-weight: 500;
}
a {
  color: #2d54e8;
}

a:hover {
  /* color: #3948b2; */
}

.errorMessage {
  color: #e02031;
  font-size: 14px;
  margin-top: 10px;
}

@media only screen and (max-width: 414px) {
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 18px;
  }
  h5 {
    font-size: 14px;
  }
  h6 {
    font-size: 13px;
  }
}
