.container {
  display: flex;
  flex-grow: 1;
  flex-flow: row wrap;
  max-width: 1776px;
  padding-top: 20px;
  margin: 0 auto;
  position: relative;
}

.error {
  color: #e02031;
  font-size: 16px;
  margin-top: 4px;
  padding-top: 20px;
}

.axis {
  font-family: 'Source Sans Pro';
}

.emptyStateContainer {
  width: 100%;
  height: 100%;
  min-height: 500px;
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.emptyStateLink {
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  margin-left: 5px;
}

.takeNowButton {
  text-transform: none !important;
  width: 100% !important;
}

.takeNowLink {
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .container {
    /* padding: 20px 20px; */
    padding-bottom: 35px;
  }

  .error {
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
  }

  .container :global(.summary-section) {
    flex: 0 0 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 1065px) and (min-width: 600px) {
  /* .container {
    padding: 20px 50px;
  } */

  .error {
    text-align: center;
  }
}
