.clinician_button {
  text-transform: none !important;
  background-color: white !important;
  width: 100% !important;
  height: 40px !important;
  font-weight: normal !important;
  font-size: 16px !important;
  padding: 10.5px 14px !important;
  margin-bottom: 8px !important;
  margin-top: 8px !important;
  border: 1px solid #d1d1d1 !important;
  color: black !important;
  max-width: 548px; /* specifically for full-enroll */
}

.clinician_button:hover {
  border: 1px solid black !important;
}

.name_arrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
