.name_and_attributes {
  max-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.name,
.lift_name {
  font-size: 30px;
  color: #333333;
  font-weight: bold;
}

.lift_name {
  margin-top: -15px;
}

.mrn {
  margin-left: 10px;
  font-size: 20px;
  color: #333333;
  font-weight: normal;
}

.attributes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.column,
.right_column,
.mobile_column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
}

.mobile_column {
  margin-top: 0;
  margin-bottom: 10px;
}

.right_column {
  margin-left: 70px;
}

.attribute {
  font-weight: bold;
}

.value {
  margin-left: 5px;
  line-height: 20px;
  font-size: 16px;
  color: #333333;
}

.link {
  color: #2d54e8;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  margin-left: 5px;
}

.navLink:hover {
  color: #2d54e8 !important;
}

.settingsLink {
  color: #4a4a4a;
  text-decoration: underline;
}

.trigger_alert {
  margin-top: 10px;
  display: flex;
  color: #db1544;
  text-transform: capitalize;
  align-items: center;
}

.trigger_alert h4 {
  padding-left: 5px;
}

@media only screen and (max-width: 414px) {
  .attributes {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .right_column {
    margin-left: 0px;
    margin-bottom: 10px;
    width: 100%;
  }
}
