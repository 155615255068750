.Paper {
  max-width: 1600px;
  width: 100%;
  padding: 40px 32px;
  margin: 0 auto;
  border-radius: 0 !important;
  box-sizing: border-box;
  min-height: calc(100% - 87px);
}

.container {
  height: 100%;
}

.error {
  color: #e02031;
  font-size: 13px;
  margin: 10px 0;
}

.no_archived_patients {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
}

@media only screen and (max-width: 414px) {
  .Paper {
    padding: 40px 0;
    margin: 0 0;
  }

  .container td {
    display: none !important;
  }

  .container td:first-child {
    display: table-cell !important;
  }

  .container td:last-child {
    display: table-cell !important;
  }
}
