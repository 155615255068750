.nav {
  height: 67px;
  background: #2d54e8;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.nav.admin {
  background: #ffffff;
}

.nav.admin input {
  background-color: rgba(0, 0, 0, 0.01);
  color: rgba(0, 0, 0, 0.8);
}

.nav.admin input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.nav.admin .nav_inner,
.nav.admin .links .link {
  color: #2d54e8;
}

.adminPill {
  background: #2d54e8;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 13px;
  margin-left: 22px;
}

.nav_inner {
  height: 67px;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.nav a {
  color: white;
}

.logoContainer {
  height: 36px;
  display: flex;
  align-items: center;
}

.logo {
  text-decoration: none;
}

.logo img {
  height: 36px;
}

@media only screen and (max-width: 414px) {
  .nav_inner {
    padding: 0 20px;
  }
}
