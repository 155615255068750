.content {
  width: 100%;
  padding-bottom: 20px;
  margin: 4px auto 0;
  box-sizing: border-box;
  background-color: #ffffff;
}
.locked {
  overflow: hidden;
  height: 100%;
}

.whiteBack {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  animation-name: contentFadeIn;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

@keyframes contentFadeIn {
  from { opacity: 0;}
  to { opacity: 1;}
}

@media only screen and (max-width: 414px) {
  .Paper {
    padding: 0;
    margin: 0;
  }
}
