.pill {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #cccccc;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-right: 5px;
  margin-bottom: 5px;
  box-sizing: border-box;
}
.label {
  padding: 6px;
  box-sizing: border-box;
}
.x {
  display: flex;
  align-items: center;
  padding: 6px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  height: 100%;
  box-sizing: border-box;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.x:hover {
  background: rgba(255, 255, 255, 0.2);
}
