.page {
  background: white;
  width: 100%;
  min-height: 100%;
  padding-top: 50px;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.header {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  padding: 0 20px;
  text-align: center;
}
.container,
.mobile_container {
  max-width: 70%;
  margin: 0 auto;
  padding: 30px 20px;
  display: flex;
  flex-flow: column;
}
.mobile_container {
  max-width: 90%;
  padding: 20px 5px;
  margin: 0;
}
.section {
  line-height: 1.25;
}
.assessment {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-bottom: 30px;
  margin-top: 20px;
}
.questions {
  box-sizing: border-box;
  margin-top: 20px;
}
.question {
  display: flex;
  flex-flow: column;
  margin: 15px 0;
  box-sizing: border-box;
}
.question_title {
  font-weight: 600;
}
.question .answers {
  display: flex;
  flex-flow: row wrap;
}
.button {
  margin: 0 auto;
}
.error {
  text-align: center;
  color: #e02031;
  font-size: 14px;
  margin-bottom: 30px;
  height: 15px;
}
.assessment_name {
  color: #4a4a4a;
  margin-bottom: 15px;
}
.submit_button,
.mobile_submit_button {
  text-transform: none !important;
  font-weight: normal !important;
  width: 50% !important;
  align-self: center;
  margin-top: -15px !important;
}
.mobile_submit_button {
  width: 70% !important;
}
