.row.clickable {
  cursor: pointer;
}
.row:nth-child(even):not(.sameColorRows) {
  background-color: #f7fafc;
  border: 0;
}
.row:hover {
  background-color: #ecf1f5 !important;
}
.row.mini {
  height: 31px;
  font-size: 13px !important;
}
.cell {
  /* border: 0 !important; */
  min-width: 80px;
}
.dates {
  border: 0 !important;
}
.bold {
  font-weight: bold !important;
}
.weekly_cell {
  min-width: 100px !important;
  /* word-wrap: break-word !important; */
  text-align: left !important;
}
.italics {
  font-style: italic;
}
.cell:last-child {
  text-align: right;
}
@media only screen and (max-width: 414px) {
  .tableHead {
    display: none !important;
  }
  .row .cell {
    padding: 8px 56px 8px 24px;
  }
}
