.recharts-text {
  fill: #a1a1a1;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: -0.6px;
}
.recharts-cartesian-grid-horizontal line:last-child {
  display: none;
}
.yAxis line, .xAxis line {
  stroke: #ccc;
}
.tooltip {
  padding: 16px;
}
.date {
  font-weight: bold;
  font-size: 13px;
}
.dot {
  width: 10px;
  height: 10px;
  margin-right: 4px;
  border-radius: 50%;
}

.rt-td, .rt-tr-group, .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td{
  border: 0 !important;
}
.ReactTable .rt-thead.-header {
  box-shadow: none !important;
  padding: 12px 0;
}
.ReactTable {
  margin-top: 32px;
  border: 0;
}
.rt-tr-group {
  cursor: pointer;
}
