.tooltipMain {
  border-bottom: 1px dashed #b2b2b2;
}
.tooltipMain > span > svg {
  display: inline;
  vertical-align: top;
}
.tooltipHover {
  flex-direction: column;
  justify-content: space-between;
}
.tooltipHoverTitle {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 10px;
}
