.iconContainer {
  height: 60px;
  width: 60px;
}
.icon {
  width: 100%;
  height: 100%;
}
.title {
  margin: 20px 0;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.body {
  margin-bottom: 20px;
  line-height: 1.25;
  width: 90%;
  text-align: center;
  white-space: pre-wrap;
}
.container {
  display: flex;
  min-height: 40%;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}
