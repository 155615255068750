.row {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  width: 100%;
}
.half {
  position: relative;
  width: calc(50% - 20px);
  flex: calc(50% - 20px);
}
.half:first-child {
  margin-right: 20px;
}
.half:last-child {
  margin-left: 20px;
}
.outlined_select select {
  padding-top: 10px;
  padding-bottom: 10px;
}
.symptoms_table {
  flex-flow: column;
  display: flex;
}
.symptoms_table .outlined_select {
  position: absolute;
  right: 0;
  top: -10px;
}
.subcardtitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
}
.symptomSelector {
  width: 100%;
  margin-bottom: 30px;
}
.symptomSelector div[role='button'] {
  padding: 10px 10px 5px;
}
.pills {
  display: flex;
  flex-flow: row wrap;
}
.pdf_subheader {
  font-family: 'Source Sans Pro';
  margin-top: -3px;
  margin-bottom: 18px;
  font-size: 18px;
  line-height: 1.25;
  color: #4a4a4a;
  flex: 0 0 100%;
  letter-spacing: 0;
  font-weight: 400;
}

@media only screen and (max-width: 414px) {
  .half {
    width: 100%;
    margin: 0 0 20px 0 !important;
    flex: 100%;
  }
  .symptoms_table .outlined_select {
    position: relative;
  }
}
